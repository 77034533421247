import {
    Error,
    GetAllByRoomReservationDtoList,
    GetMeetingRoomReservationDto,
    GetQuickItemsMeetingRoomReservationDto,
    PageRoomReservationRes,
    ResponseDto,
    RoomQuickReservationReq,
    RoomReservationRes,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MeetingRoomReservationController<SecurityDataType = unknown> {
    http: HttpClient<SecurityDataType>

    constructor(http: HttpClient<SecurityDataType>) {
        this.http = http
    }

    /**
     * @description 예약 수정 하기(빠진 참여자는 삭제, 추가된 사용자는 추가)
     *
     * @tags meeting-room-reservation-controller
     * @name UpdateQuickReserve
     * @summary 회의실 빠른 예약 수정
     * @request PUT:/api/meeting-rooms/{roomId}/quick-reserve/{reservationId}
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    updateQuickReserve = (
        roomId: number,
        reservationId: number,
        data: RoomQuickReservationReq,
        params: RequestParams = {}
    ) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/meeting-rooms/${roomId}/quick-reserve/${reservationId}`,
            method: 'PUT',
            body: data,
            type: ContentType.Json,
            ...params,
        })
    /**
     * @description 회의실 종료
     *
     * @tags meeting-room-reservation-controller
     * @name EndReservation
     * @summary 회의실 종료
     * @request PUT:/api/meeting-rooms/reservations/{reservationId}/end
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    endReservation = (reservationId: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/meeting-rooms/reservations/${reservationId}/end`,
            method: 'PUT',
            ...params,
        })
    /**
     * @description 회의실 빠른 예약 후, 참석 초대인원 초대
     *
     * @tags meeting-room-reservation-controller
     * @name QuickReserve
     * @summary 회의실 빠른 예약하기
     * @request POST:/api/meeting-rooms/{roomId}/quick-reserve
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    quickReserve = (roomId: number, data: RoomQuickReservationReq, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/meeting-rooms/${roomId}/quick-reserve`,
            method: 'POST',
            body: data,
            type: ContentType.Json,
            ...params,
        })
    /**
     * @description 회의실 빠른예약 기능 아이템 가져오기
     *
     * @tags meeting-room-reservation-controller
     * @name GetReservationQuickItem
     * @summary 빠른 예약 기능 아이템 가져오기(예약된 회의실)
     * @request GET:/api/meeting-rooms/{roomId}/reserve/{reservationId}/quick-items
     * @response `200` `GetQuickItemsMeetingRoomReservationDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getReservationQuickItem = (roomId: number, reservationId: number, params: RequestParams = {}) =>
        this.http.request<GetQuickItemsMeetingRoomReservationDto, Error>({
            path: `/api/meeting-rooms/${roomId}/reserve/${reservationId}/quick-items`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 회의실 빠른예약 기능 아이템 가져오기
     *
     * @tags meeting-room-reservation-controller
     * @name GetQuickItems
     * @summary 빠른 예약 기능 아이템 가져오기
     * @request GET:/api/meeting-rooms/{roomId}/reserve/quick-items
     * @response `200` `GetQuickItemsMeetingRoomReservationDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getQuickItems = (roomId: number, params: RequestParams = {}) =>
        this.http.request<GetQuickItemsMeetingRoomReservationDto, Error>({
            path: `/api/meeting-rooms/${roomId}/reserve/quick-items`,
            method: 'GET',
            ...params,
        })
    /**
     * @description 해당 회의실 id를 통해 검색
     *
     * @tags meeting-room-reservation-controller
     * @name GetMeetingRoomReservationListByStartAt
     * @summary 검색 조회 (회의실 id)
     * @request GET:/api/meeting-rooms/{roomId}/reservations/start-at/{startAt}
     * @response `200` `GetAllByRoomReservationDtoList` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getMeetingRoomReservationListByStartAt = (roomId: number, startAt: number, params: RequestParams = {}) =>
        this.http.request<GetAllByRoomReservationDtoList, Error>({
            path: `/api/meeting-rooms/${roomId}/reservations/start-at/${startAt}`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags meeting-room-reservation-controller
     * @name GetReservationById
     * @request GET:/api/meeting-rooms/reserves/{reservationId}
     * @response `200` `RoomReservationRes` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getReservationById = (reservationId: number, params: RequestParams = {}) =>
        this.http.request<RoomReservationRes, Error>({
            path: `/api/meeting-rooms/reserves/${reservationId}`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags meeting-room-reservation-controller
     * @name GetRoomReservationsByStartAt
     * @request GET:/api/meeting-rooms/reservations/start-at/{startAt}
     * @response `200` `(GetMeetingRoomReservationDto)[]` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    getRoomReservationsByStartAt = (startAt: number, params: RequestParams = {}) =>
        this.http.request<GetMeetingRoomReservationDto[], Error>({
            path: `/api/meeting-rooms/reservations/start-at/${startAt}`,
            method: 'GET',
            ...params,
        })
    /**
     * No description
     *
     * @tags meeting-room-reservation-controller
     * @name RoomReservationsDatatable
     * @request GET:/api/meeting-rooms/reservations/datatable
     * @response `200` `PageRoomReservationRes` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    roomReservationsDatatable = (
        query?: {
            /** @format int64 */
            floorId?: number
            /** @format int64 */
            roomId?: number
            /** @format int64 */
            ownerUserId?: number
            /** @format int64 */
            attendeeUserId?: number
            title?: string
            description?: string
            status?: 'READY' | 'USING' | 'COMPLETED' | 'CANCELED'
            /** @format date */
            searchFrom?: string
            /** @format date */
            searchTo?: string
            /** @format int32 */
            capacity?: number
            capacityExpression?: 'EQ' | 'LT' | 'LOE' | 'GT' | 'GOE'
            /** @format int32 */
            page?: number
            /** @format int32 */
            pageSize?: number
        },
        params: RequestParams = {}
    ) =>
        this.http.request<PageRoomReservationRes, Error>({
            path: `/api/meeting-rooms/reservations/datatable`,
            method: 'GET',
            query: query,
            ...params,
        })
    /**
     * @description 회의실 예약 id를 통해 삭제
     *
     * @tags meeting-room-reservation-controller
     * @name DeleteReservationById
     * @summary 회의실 예약 삭제
     * @request DELETE:/api/meeting-rooms/reservations/{reservationId}
     * @response `200` `ResponseDto` OK
     * @response `400` `Error` Bad Request
     * @response `401` `Error` Unauthorized
     * @response `403` `Error` Forbidden
     * @response `500` `Error` Internal Server Error
     */
    deleteReservationById = (reservationId: number, params: RequestParams = {}) =>
        this.http.request<ResponseDto, Error>({
            path: `/api/meeting-rooms/reservations/${reservationId}`,
            method: 'DELETE',
            ...params,
        })
}
