// import './styles.css'
import { CssBaseline } from '@mui/material'
import { NextComponentType } from 'next' //styles of nprogress
import { AppContext, AppInitialProps, AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { SSRProvider } from 'react-bootstrap'
// import './response.css'
import 'simple-keyboard/build/css/index.css'
import { SWRConfig } from 'swr'
import { ConfirmDto, LoginDto } from '../__generated__/data-contracts'
import ConfirmModal from '../components/common/ConfirmModal'
import InspectionModal from '../components/common/InspectionModal'
import LoadingIndicator from '../components/common/LoadingIndicator'
import httpClient from '../dao/http-clients/httpClient'
import { clientMyReservationController } from '../dao/myReservationController'
import { MyConfirmations } from '../dao/swr'
import { ConfirmType } from '../domain/common'
import fetch from '../lib/fetchJson'
import withSession from '../lib/session'
import useRouteLoading from '../lib/useRouteLoading'
import '../support/bridge'
import '../support/extension'
import { AppPropsWithLayout } from '../support/nextjs'
import './datePickerStyles.css'
import './font.css'
import './kiosk.css'
// import './main.css'
import './tablet.css'
import 'react-datepicker/dist/react-datepicker.css'
import Script from "next/script";

interface ConfirmInfo {
    id: number
    message: string
}

export type MqvAppPropsWithLayout = AppProps & { user?: LoginDto }

const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps<{ user?: LoginDto }>> = ({
    pageProps,
    Component,
}: AppPropsWithLayout) => {
    const router = useRouter()
    const getLayout = Component.getLayout || ((page) => page)
    const user: LoginDto | undefined = pageProps['user']
    if (user) {
        httpClient.setSecurityData(user.access_token)
    }
    const [loading] = useRouteLoading()
    const [inspectionModal, setInspectionModal] = useState(false) //점검중 표시

    useEffect(() => {
        const url = window.location.href

        if (url === '') return

        let home: string

        if (window.location.pathname.startsWith('/login')) {
            home = url
        } else {
            const host = window.location.host
            home = url.substring(0, url.indexOf(host)) + host + '/m/home'
        }

        if (window?.webkit) {
            window?.webkit?.messageHandlers?.changeUrl?.postMessage(home)
        } else if (window?.android) {
            window?.android?.changeUrl(home)
        }
    }, [router.asPath])

    const [roomConfirms, setRoomConfirms] = useState<ConfirmDto[]>([])
    const [seatConfirms, setSeatConfirms] = useState<ConfirmDto[]>([])

    const { data: reservationConfirm } = MyConfirmations()
    useEffect(() => {
        if (reservationConfirm) {
            setRoomConfirms(reservationConfirm.rooms)
            setSeatConfirms(reservationConfirm.seats)
        }
    }, [reservationConfirm])

    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmInfo, setConfirmInfo] = useState<ConfirmInfo>()
    useEffect(() => {
        if (window?.location.href.includes('kiosk')) {
            console.log('kiosk')
        } else if (reservationConfirm) {
            const roomConfirmations = roomConfirms.filter((it) => it.confirmType == ConfirmType.START)
            if (roomConfirmations.length > 0) {
                setConfirmInfo({
                    id: roomConfirmations[0].confirmId,
                    message: `[${roomConfirmations[0].name}] 회의실 을 최종 사용 하시겠습니까? \n (아니오 시 예약이 취소됩니다.)`,
                })

                setConfirmModal(true)
            } else if (seatConfirms.length > 0) {
                const seatInfo = seatConfirms[0]
                console.log('seatConfirms', seatInfo)
                const id = seatInfo.confirmId
                const name = seatInfo.name
                let msg = ''

                if (seatInfo.confirmType == ConfirmType.START) {
                    msg = `[${name}] 좌석 을 최종 사용 하시겠습니까? \n (아니오 시 예약이 취소됩니다.)`
                } else if (seatInfo.confirmType == ConfirmType.USING) {
                    msg = `[${name}] 좌석 을 계속 사용 하시겠습니까? \n (아니오 시 사용이 종료됩니다.)`
                }

                setConfirmInfo({ id: id, message: msg })
                setConfirmModal(true)
            }
        }
    }, [roomConfirms, seatConfirms])

    const onClickConfirmOk = () => {
        if (confirmInfo) {
            clientMyReservationController.confirmAccept(confirmInfo.id).then(() => setConfirmModal(false))
        }
    }
    const onClickConfirmCancel = () => {
        if (confirmInfo) {
            clientMyReservationController.confirmReject(confirmInfo.id).then(() => setConfirmModal(false))
        }
    }
    return (
        <SSRProvider>
            <Script src='/scripts/eventsource/eventsource.min.js'></Script>
            <Head>
                <meta charSet='utf-8' />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                />

                <meta httpEquiv='Pragma' content='no-cache' />
                <meta httpEquiv='Expires' content='-1' />
            </Head>
            <CssBaseline />
            <SWRConfig
                value={{
                    fetcher: fetch,
                    onError: (error) => {
                        const url = window?.location?.href || ''
                        if (url.includes('/tablet')) {
                            console.log('bad request')
                        } else {
                            if (error.toString().includes('Forbidden')) {
                                router.push('/login')
                            } else if (error.toString().includes('Service Unavailable')) {
                                setInspectionModal(true)
                                //alert("잠시 긴급 점검 중 입니다. \n이용에 불편을 드려 죄송합니다.")
                            } else {
                                // if (window?.webkit) {
                                //     window?.webkit?.messageHandlers?.logout?.postMessage(`${window.location.origin}`)
                                // } else if (window?.android) {
                                //     window?.android?.logout(`${window.location.origin}`)
                                // }
                            }
                        }
                    },
                }}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : inspectionModal ? (
                    <InspectionModal show={inspectionModal} setShow={setInspectionModal} size={'sm'} />
                ) : confirmModal ? (
                    <ConfirmModal
                        message={confirmInfo?.message}
                        cancel={onClickConfirmCancel}
                        ok={onClickConfirmOk}
                        show={confirmModal}
                    />
                ) : (
                    getLayout(<Component {...pageProps} />)
                )}
            </SWRConfig>
        </SSRProvider>
    )
}

MyApp.getInitialProps = async ({ Component, ctx }: AppContext): Promise<AppInitialProps> => {
    let pageProps = {}
    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx)
    }

    return withSession(({ req }) => {
        return {
            pageProps: {
                ...pageProps,
                user: req.session.get('user'),
            },
        }
    })(ctx)
}

export default MyApp
