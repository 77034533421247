export enum HttpStatus {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZATION = 401,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOW = 405,
    INTERNAL_ERROR = 500,
    SERVICE_UNAVAILABLE = 503 //서버 재기동시 점검표시로
}

export const CONST = {
    DAYS: ['일', '월', '화', '수', '목', '금', '토'],
}

export enum HttpHeader {
    USER_TOKEN = 'X-AUTH-TOKEN',
}
